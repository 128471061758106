<template>
  <section class="users-page">
    <AddNew/>
    <List />
    <ChangePassModal />
    <DeleteModal />
    <AddModal />
  </section>
</template>

<script>
export default {
  name: "UsersPage",
  components: {
    ChangePassModal: () => import('@/components/modals/UserChangePassModal'),
    AddModal: () => import('@/components/modals/UserAddModal'),
    DeleteModal: () => import('@/components/modals/UserDeleteModal'),
    AddNew: () => import('@/components/users/UsersAddUser'),
    List: () => import('@/components/users/UsersList'),
  },
}
</script>

<style scoped>

</style>
